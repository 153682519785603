import React, { useEffect } from 'react'
import { PageProps, navigate } from 'gatsby'
import Seo from '../components/utils/seo'

const NotFoundPage: React.FC<PageProps<GatsbyTypes.IndexPageQuery>> = () => {
    useEffect(() => {
        navigate('/404/')
    }, [])
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>
}

export const Head = () => <Seo _title="404" />

export default NotFoundPage
